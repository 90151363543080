// TODO what's in it for the speaker

import {Layout} from "../components/layout/layout";
import Seo from "../components/seo/seo";
import React from "react";
import style from "../assets/css/conference.module.css";
import {useIntl} from "gatsby-plugin-intl";
import {SsyIntersectionObserver} from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import {RaisingBox} from "../components/raisingBox/raisingBox";
import {SsyLink} from "../components/ssyLink/ssyLink";
import {graphql, useStaticQuery} from "gatsby";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {GatsbyImage} from "gatsby-plugin-image";
import {ConferencePartnerBanner} from "../components/conferencePartnerBanner/conferencePartnerBanner";
import {EnterpriseImageTile, EnterpriseTextTile} from "../components/enterpriseTile/enterpriseTiles";
import HtmlPrinter from "../components/htmlPrinter/htmlPrinter";
import './conference.css';

const ConferencePage = (props) => {
    const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "enterprise/conferences/header.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            height: 800
             width: 800
            placeholder: BLURRED
            blurredOptions: { width: 400 }
            quality: 50
          )
        }
      }
      
      branding: file(relativePath: { eq: "enterprise/conferences/branding.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      communication: file(relativePath: { eq: "enterprise/conferences/communication.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      eventFeedback: file(relativePath: { eq: "enterprise/conferences/event-feedback.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      schedule: file(relativePath: { eq: "enterprise/conferences/schedule.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      scheduleDay: file(relativePath: { eq: "enterprise/conferences/schedule-day.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      session: file(relativePath: { eq: "enterprise/conferences/session.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      speakers : file(relativePath: { eq: "enterprise/conferences/speakers.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      sponsors: file(relativePath: { eq: "enterprise/conferences/sponsors.jpg" }) {
       childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 300
            placeholder: BLURRED
          )
        }
      }
      
      analytics: file(relativePath: { eq: "enterprise/conferences/analytics.png" }) {
         childImageSharp {
             gatsbyImageData(
                formats: [WEBP]
                layout: CONSTRAINED
                height: 300
                placeholder: BLURRED
             )
          }
        }
        
        setup: file(relativePath: { eq: "enterprise/conferences/global-setup.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        formats: [WEBP]
                        layout: CONSTRAINED
                        height: 300
                        placeholder: BLURRED
            )
            }
        }
        
        feedbackManagement: file(relativePath: { eq: "enterprise/conferences/feedback-management.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        formats: [WEBP]
                        layout: CONSTRAINED
                        height: 300
                        placeholder: BLURRED
            )
            }
        }
        
        infoPage: file(relativePath: { eq: "enterprise/conferences/info-page.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        formats: [WEBP]
                        layout: CONSTRAINED
                        height: 300
                        placeholder: BLURRED
            )
            }
        }
        
        partnerManagement: file(relativePath: { eq: "enterprise/conferences/partner-management.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        formats: [WEBP]
                        layout: CONSTRAINED
                        height: 300
                        placeholder: BLURRED
            )
            }
        }
        
        scheduleSettings: file(relativePath: { eq: "enterprise/conferences/schedule-settings.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        formats: [WEBP]
                        layout: CONSTRAINED
                        height: 300
                        placeholder: BLURRED
            )
            }
        }
    }
    `);
    const intl = useIntl();
    const breakpoints = useBreakpoint();

    const headerImage = <div className={style.headerImage}>
        <GatsbyImage
            alt="Banner showing the customized Sam App"
            image={data["header"].childImageSharp.gatsbyImageData}
        />

    </div>;

    const buttonLearnMore = {
        id: "ENTERPRISE_LEARN_MORE",
        text: "ENTERPRISE_LEARN_MORE",
        link: `${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`
    };

    const buttonContactUs = {
        id: "ENTERPRISE_GET_IN_TOUCH",
        text: "ENTERPRISE_GET_IN_TOUCH",
        link: `${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`
    };

    const organizer = <EnterpriseTextTile
        intl={intl}
        index={0}
        rational={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_RATIONAL"})}
        title={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_TITLE"})}
        childContent={
            <div className={style.benefitsSection} style={{textAlign: "left", color: "#738c98"}}>
                <ul>

                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_BENEFIT_1"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_BENEFIT_2"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_BENEFIT_3"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_BENEFIT_4"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_BENEFIT_6"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ORGANIZERS_BENEFIT_7"})}/></li>
                </ul>
            </div>
        }
        button={buttonLearnMore}
    />;

    const organizerImage = <
        EnterpriseImageTile
        image={data["eventFeedback"].childImageSharp.gatsbyImageData}
        index={0}
    />;


    const attendee = <EnterpriseTextTile
        intl={intl}
        index={2}
        rational={intl.formatMessage({id: "CONFERENCE_ATTENDEES_RATIONAL"})}
        title={intl.formatMessage({id: "CONFERENCE_ATTENDEES_TITLE"})}
        childContent={
            <div className={style.benefitsSection} style={{textAlign: "left", color: "#738c98"}}>
                <ul>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ATTENDEES_BENEFIT_1"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ATTENDEES_BENEFIT_2"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ATTENDEES_BENEFIT_3"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ATTENDEES_BENEFIT_4"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_ATTENDEES_BENEFIT_5"})}/></li>
                </ul>
            </div>
        }
        button={buttonLearnMore}
    />;

    const attendeeImage = <
        EnterpriseImageTile
        image={data["schedule"].childImageSharp.gatsbyImageData}
        index={2}
    />;

    const sponsor = <EnterpriseTextTile
        intl={intl}
        index={1}
        rational={intl.formatMessage({id: "CONFERENCE_SPONSORS_RATIONAL"})}
        title={intl.formatMessage({id: "CONFERENCE_SPONSORS_TITLE"})}
        childContent={

            <div className={style.benefitsSection} style={{textAlign: "left", color: "#738c98"}}>
                <ul>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_SPONSORS_BENEFIT_1"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_SPONSORS_BENEFIT_2"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_SPONSORS_BENEFIT_3"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_SPONSORS_BENEFIT_4"})}/></li>
                    <li><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_SPONSORS_BENEFIT_5"})}/></li>
                </ul>
            </div>
        }
        button={buttonContactUs}
    />;

    const sponsorImage = <
        EnterpriseImageTile
        image={data["sponsors"].childImageSharp.gatsbyImageData}
        index={1}
    />;

    return (
        <Layout>
            <Seo
                title={intl.formatMessage({id: "CONFERENCE_META_TITLE"})}
                url={`${props.location.pathname}/conference`}
                lang={intl.formatMessage({id: "MAIN_LANGUAGE"})}
                description={intl.formatMessage({id: "CONFERENCE_META_DESCRIPTION"})}
            />

            <main className={style.pageStyles}>
                <SsyIntersectionObserver>
                    <RaisingBox>
                        <div className={`${style.headerSection} ${style.firstPage}`}>
                            {breakpoints.sm ?
                                headerImage
                                : <></>}
                            <section className={`${style.section}`}>
                                <h1>{intl.formatMessage({id: "CONFERENCE_TITLE"})}</h1>
                                <h3 className={style.headerDescription}>{intl.formatMessage({id: "CONFERENCE_MESSAGE"})}</h3>
                                <SsyLink
                                    type="primary"
                                    id="ENTERPRISE_BOOK_DEMO"
                                    to={`${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`}
                                    internal={false}
                                    label={intl.formatMessage({id: "ENTERPRISE_BOOK_DEMO"})}
                                />
                            </section>
                            {!breakpoints.sm ?
                                headerImage
                                : <></>}
                        </div>
                    </RaisingBox>
                </SsyIntersectionObserver>


                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={style.section}>

                            {breakpoints.sm ?
                                <div className={style.gridReason}>
                                    {organizer}
                                    {organizerImage}
                                    {sponsor}
                                    {sponsorImage}
                                    {attendee}
                                    {attendeeImage}
                                </div>
                                :
                                <div className={style.gridReason}>
                                    {organizer}
                                    {organizerImage}
                                    {sponsorImage}
                                    {sponsor}
                                    {attendee}
                                    {attendeeImage}
                                </div>
                            }

                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>


                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={`${style.section} ${style.collabs}`}>
                            <h2>
                                {intl.formatMessage({id: "CONFERENCE_TESTIMONIAL_TITLE"})}
                            </h2>
                            <ConferencePartnerBanner/>
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>

                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={`${style.section} ${style.collabs}`}>
                            <h2>
                                {/*TODO copy pricing design https://sessionize.com/pricing*/}
                                {intl.formatMessage({id: "CONFERENCE_PRICING_TITLE"})}
                            </h2>
                            <h3> {intl.formatMessage({id: "CONFERENCE_PRICING_SUBTITLE"})}</h3>
                            <p><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_PRICING_BODY"})}/></p>
                            <SsyLink
                                type="primary"
                                id="ENTERPRISE_GET_IN_TOUCH"
                                to={`${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`}
                                internal={false}
                                label={intl.formatMessage({id: "ENTERPRISE_GET_IN_TOUCH"})}
                            />
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>

                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={`${style.section}`}>
                            <h2>
                                {intl.formatMessage({id: "CONFERENCE_FEATURES_TITLE"})}
                            </h2>
                            <div className={style.featuresBox}>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Branding"
                                        image={data["branding"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_BRANDING_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_BRANDING_SUBTITLE"})}</p>
                                </div>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Communication"
                                        image={data["communication"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_COMMUNICATION_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_COMMUNICATION_SUBTITLE"})}</p>
                                </div>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Event feedback"
                                        image={data["eventFeedback"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_EVENTFEEDBACK_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_EVENTFEEDBACK_SUBTITLE"})}</p>
                                </div>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Schedule"
                                        image={data["schedule"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_SCHEDULE_TITLE"})}</h3>
                                    <p><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_FEATURES_SCHEDULE_SUBTITLE"})} /></p>
                                </div>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Schedule Day"
                                        image={data["scheduleDay"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_SCHEDULEDAY_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_SCHEDULEDAY_SUBTITLE"})}</p></div>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Session"
                                        image={data["session"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_SESSION_TITLE"})}</h3>
                                    <p><HtmlPrinter content={intl.formatMessage({id: "CONFERENCE_FEATURES_SESSION_SUBTITLE"})} /></p>
                                </div>
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Speakers"
                                        image={data["speakers"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_SPEAKERS_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_SPEAKERS_SUBTITLE"})}</p>
                                </div>

                                <div className="feature">
                                    <GatsbyImage
                                        alt="Global setup"
                                        image={data["setup"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_BULK_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_BULK_SUBTITLE"})}</p>
                                </div>
                                
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Feedback management"
                                        image={data["feedbackManagement"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_FEEDBACK_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_FEEDBACK_SUBTITLE"})}</p>
                                </div>
                                
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Info page"
                                        image={data["infoPage"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_INFO_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_INFO_SUBTITLE"})}</p>
                                </div>
                                
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Partner management"
                                        image={data["partnerManagement"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_PARTNER_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_PARTNER_SUBTITLE"})}</p>
                                </div>
                                
                                <div className="feature">
                                    <GatsbyImage
                                        alt="Schedule settings"
                                        image={data["scheduleSettings"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_SCHEDULESETTINGS_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_SCHEDULESETTINGS_SUBTITLE"})}</p>
                                </div>

                                <div className="feature">
                                    <GatsbyImage
                                        alt="Analytics"
                                        image={data["analytics"].childImageSharp.gatsbyImageData}
                                    />
                                    <h3>{intl.formatMessage({id: "CONFERENCE_FEATURES_ANALYTICS_TITLE"})}</h3>
                                    <p>{intl.formatMessage({id: "CONFERENCE_FEATURES_ANALYTICS_SUBTITLE"})}</p>
                                </div>
                            </div>
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>

                {/*Add a section with a header "Missing anything?" and a button "Contact us" that links to the contact page*/}
                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={`${style.section}`}>
                            <h2>
                                {intl.formatMessage({id: "CONFERENCE_MISSING_FEATURES_TITLE"})}
                            </h2>
                            <SsyLink
                                type="primary"
                                id="ENTERPRISE_GET_IN_TOUCH"
                                to={`${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`}
                                internal={false}
                                label={intl.formatMessage({id: "ENTERPRISE_GET_IN_TOUCH"})}
                            />
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>

                {/*TODO add how to setup*/}
                {/*<SsyIntersectionObserver>*/}
                {/*    <RaisingBox>*/}
                {/*        <div className={style.contentSection}>*/}
                {/*            <h2>Effortless setup</h2>*/}
                {/*            <ol>*/}
                {/*                <li>Create event</li>*/}
                {/*                <li>Enter your <a href="https://sessionize.com">Sessionize</a> api key</li>*/}
                {/*                <li>Press the magic "Update" button ✅</li>*/}
                {/*            </ol>*/}
                {/*        </div>*/}
                {/*    </RaisingBox>*/}
                {/*</SsyIntersectionObserver>*/}
            </main>
        </Layout>
    )
}

export default ConferencePage;