import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import style from "./conferencePartnerBanner.module.css";
import {SsyLink} from "../ssyLink/ssyLink";
import {useIntl} from "gatsby-plugin-intl";

export const ConferencePartnerBanner = () => {
    const data = useStaticQuery(graphql`
    query {
      
      dddnorth: file(relativePath: { eq: "enterprise/logos/ddd-north-icon.png" }) {
        childImageSharp {
          gatsbyImageData(
              height: 30
            formats: [WEBP]
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      
      rik: file(relativePath: { eq: "enterprise/conferences/rik.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 120
            formats: [WEBP]
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      
      maarten: file(relativePath: { eq: "enterprise/conferences/maarten.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 120
            formats: [WEBP]
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      
      magnus: file(relativePath: { eq: "enterprise/conferences/magnus.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 120
            formats: [WEBP]
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      
      cloudbrew: file( relativePath: { eq: "enterprise/logos/cloudbrew.png" } ) {
        childImageSharp {
          gatsbyImageData(
            height: 30
            formats: [WEBP]
            layout: FIXED
            placeholder: BLURRED
          )
        }
      } 
      
      globalazure: file( relativePath: { eq: "enterprise/logos/global-azure-2024.png" } ) {
        childImageSharp {
          gatsbyImageData(
            height: 30
            formats: [WEBP]
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

    const intl = useIntl();

    return (
        <div className={style.partnerBannerRow}>
            <div className={style.partnerContainer}>
                <div className={style.imageContainer}>
                    <GatsbyImage
                        style={{ borderRadius: '100%' }}
                        imgStyle={{ borderRadius: '100%' }}
                        alt="Maarten Balliauw"
                        image={data["maarten"].childImageSharp.gatsbyImageData}
                    />
                </div>
                <div>
                    <h4>Maarten Balliauw</h4>
                    <p className={style.secondaryText}>With the Sam app, our attendees could consult the conference agenda, and share pics of their experience along the way. Partners also join in the conversation - definitely a value-add for our event!</p>
                    <h5>Cloudbrew - Organizer</h5>
                   
                   
                </div>

                <div className={style.linkContainer}>
                    <GatsbyImage
                        alt="Cloubrew"
                        image={data["cloudbrew"].childImageSharp.gatsbyImageData}
                    />
                    <div className={style.readMoreLink}>
                    <SsyLink
                        label={intl.formatMessage({id: "CONFERENCE_TESTIMONIAL_READMORE"})} internal={false} to={"https://cloudbrew.be"} />
                    </div>
                </div>
            </div>

            <div className={style.partnerContainer}>
                <div className={style.imageContainer}>
                    <GatsbyImage
                        style={{ borderRadius: '100%' }}
                        alt="Rik Hepworth"
                        image={data["rik"].childImageSharp.gatsbyImageData}
                    />
                </div>
                <div className={style.referalContainer}>
                    <h4>Rik Hepworth</h4>
                    <p className={style.secondaryText}>Having Sam on our team really made things easier for us: Integration with Sessionize made sharing our agenda a breeze, and the ability to collect feedback on both sessions and the event as a whole saved us time and encouraged more attendees to share their thoughts with us.</p>
                    <h5>DDD north - Organizer</h5>
                    
                    
                </div>

                <div className={style.linkContainer}>
                    <GatsbyImage
                        alt="DDD North"
                        image={data["dddnorth"].childImageSharp.gatsbyImageData}
                    />
                    <div className={style.readMoreLink}>
                        <SsyLink label={intl.formatMessage({id: "CONFERENCE_TESTIMONIAL_READMORE"})} internal={false} to={"https://dddnorth.co.uk"}></SsyLink>
                    </div>
                </div>
            </div>

            <div className={style.partnerContainer}>
                <div className={`${style.imageContainer}`}>
                    <GatsbyImage
                        style={{ borderRadius: '100%' }}
                        alt="Magnus Mårtensson"
                        image={data["magnus"].childImageSharp.gatsbyImageData}
                    />
                </div>
    
                <div>
                    <h4>Magnus Mårtensson</h4>
                    <p className={style.secondaryText}>As Global Azure is GLOBAL it is excellent to have a bridging technology like Sam app to make all our attendees feel connected to all other cloud enthusiastic technologists around the world. Texting is fine, but sharing photos from everywhere is magic! We are giddy with excitement over the collaboration to help make this community event truly a global family thanks to Sam!</p>
                    <h5>Global Azure - Organizer</h5>
                    
                </div>
                <div className={style.linkContainer}>
                    <GatsbyImage
                        alt="Global Azure"
                        image={data["globalazure"].childImageSharp.gatsbyImageData}
                    />
                    <div className={style.readMoreLink}>
                        <SsyLink label={intl.formatMessage({id: "CONFERENCE_TESTIMONIAL_READMORE"})} internal={false} to={"https://globalazure.net"}></SsyLink>
                    </div>
                  
                </div>
            </div>


    {/*TODO add once demo is ready*/}
            {/*<div className={`${style.imageContainer} ${style.hideOnMobile}`}>*/}
            {/*    <GatsbyImage*/}
            {/*        alt="Braut"*/}
            {/*        image={data["dddsw"].childImageSharp.gatsbyImageData}*/}
            {/*    />*/}
            {/*</div> */}
            {/*<div className={`${style.imageContainer} ${style.hideOnMobile}`}>*/}
            {/*    <GatsbyImage*/}
            {/*        alt="Braut"*/}
            {/*        image={data["techorama"].childImageSharp.gatsbyImageData}*/}
            {/*    />*/}
            {/*</div>*/}
          
        </div>
    );
};
